<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">{{ translations.tcAddTestimonySpeakers }}</h1>
    </page-header>
    <page-body class>
      <section class="section-1 bg-block mb-4">
        <h1 class="sm mb-4">{{ translations.tcAddTestimonySpeakers }}</h1>
        <div class="body">
          <div v-if="this.isNonMemberAdd" class="row">
            <div class="col-6">
              <b-button variant="secondary" @click="handleCreateClick" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">{{
                translations.tcCreate
              }}</b-button>
            </div>
          </div>
          <b-form>
            <div class="row">
              <div class="col-6">
                <b-form-group class="form-element">
                  <div class="date-select-1 mb-1">
                    <label class="font-style-1">{{ translations.tcPresenter }} *</label>
                  </div>
                  <div class="g-row mb-0 mb-sm-3">
                    <div class="g-col-1 d-flex mb-3 mb-sm-0">
                      <b-form-select :title="translations.tcPresenter" v-model="data.acr_ind_key"
                        :options="presenterOption" class="g-select flex-0 mr-3 mb-3" required></b-form-select>
                      <b-button :name="`findSpeakerButton`" variant="primary" class="flex-1 btn-pl-xsm"
                        @click="showModal($event, 'personSearchModal', 'presenter')">{{
                          translations.tcFind
                        }}</b-button>
                      <template slot="first">
                        <option :value="null" disabled>{{ translations.tcSelect }}</option>
                      </template>
                    </div>
                  </div>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <b-form-group class="form-element">
                  <div class="date-select-1 mb-1">
                    <label class="font-style-1">{{ translations.tcTestimonyType }} *</label>
                  </div>
                  <div class="date-select-1 mb-3">
                    <b-form-select :title="translations.tcTestimonyType" v-model="tst_key"
                      :options="optionTypesTranslated" class="g-select flex-0 mr-3 mb-3" required></b-form-select>
                  </div>
                </b-form-group>
              </div>
            </div>
          </b-form>
        </div>
      </section>
      <section class="section-5 pt-3 pl-0 pl-sm-4">
        <div class="body">
          <div class="d-flex">
            <b-button variant="primary" @click="handleSaveClick" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">{{
              translations.tcSave
            }}</b-button>
            <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">{{
              translations.tcCancel
            }}</b-button>
          </div>
        </div>
      </section>
    </page-body>
    <PersonSearch @selectionMade="applyFoundPerson($event)" :camp_country="userCountry.key"
      :not_a_member="SearchValues.member" :members_only_flag="SearchValues.nonMember">
    </PersonSearch>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import PersonSearch from '@/components/PersonSearch.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add_testimony_speaker',
  props: ['item'],
  mixins: [translationMixin],
  data() {
    return {
      optionTypesTranslated: [],
      translations: {},
      findButton: '',
      contactOption: [],
      presenterOption: [],
      shouldEdit: false,
      tst_key: '',
      presetTestimonyType: false,
      currentSelection: '',
      date: null,
      data: {
        ixo_key: null,
        ixa_key: null,
        acr_ind_key: '',
        tst_key: '',
        org_key: '',
        user_ind_key: '',
        start_date: null,
      },
    }
  },
  methods: {
    ...mapActions({
      clearNewNonMemberTestimonySpeaker: 'user/clearNewNonMemberTestimonySpeaker',
      getIndividualTestimonyType: 'directory/getIndividualTestimonyType',
      getTestimonyTypes: 'directory/getTestimonyTypes',
      setLoadingStatus: 'menu/setLoadingStatus',
      upsertTestimonySpeaker: 'directory/upsertTestimonySpeaker',
    }),
    handleDateSelected($evt) {
      this.data.start_date = new Date($evt).toDateString()
    },
    showModal(e, modalName, selection) {
      this.currentSelection = selection
      this.findButton = !e.target ? e : e.target.name
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    async applyFoundPerson($event) {
      this.setLoadingStatus(true)
      this.data.acr_ind_key = $event.ind_key
      let option = { value: $event.ind_key, text: $event.name_text }
      this.presenterOption.push(option)
      await this.getIndividualTestimonyType($event.ind_key)
      this.setLoadingStatus(false)
      if (this.individualTestimonyType !== null || this.individualTestimonyType !== '')
        this.tst_key = this.individualTestimonyType.tst_key
    },
    validate() {
      if (!this.data.acr_ind_key) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcPleaseSelectASpeaker,
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
        return false
      }
      if (!this.tst_key) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcPleaseSelectATestimonyType,
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
        return false
      }
      if (!this.data.org_key || !this.data.user_ind_key || !this.data.start_date) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorMessage,
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
        return false
      }
      return true
    },
    handleCreateClick() {
      this.$router.push({ name: 'testimony-speaker-create-non-member' })
    },
    handleSaveClick() {
      if (!this.validate()) {
        return
      }
      let data = this.data
      this.data.tst_key = this.tst_key
      this.data.start_date = new Date().toDateString()
      this.submitRequest(data)
    },
    handleCancelClick() {
      this.$router.push({ name: 'testimony-speakers' })
    },
    async submitRequest(data) {
      try {
        this.setLoadingStatus(true)
        let response = await this.upsertTestimonySpeaker(data)
        this.setLoadingStatus(false)
        if (!!response) {
          this.$swal({
            icon: 'success',
            text: this.translations.tcSuccessMessage, //'Successfully added speaker',
            confirmButtonText: this.translations.tcOk || 'Ok',
          })
          this.$router.push({ name: 'testimony-speakers' })
          return
        }
      } catch (error) {
        this.setLoadingStatus(false)
        this.$swal({
          icon: 'error',
          text: this.translations.tcAdminErrorMessage, //'There was a problem in updating this speaker, Refresh and try again or contact your admin',
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
      }
    },
    async page_load() {
      await this.getTestimonyTypes()
      this.data.user_ind_key = this.userId
      this.data.start_date = new Date().toDateString()
      this.data.org_key = this.userStateKey

      // Check to see if a non-member testimony speaker option is set,
      // and if so inject the new non-member to allow user ability to complete
      // workflow of adding the new non-member as a testimony speaker.
      if (!!this.newNonMemberTestimonySpeaker) {
        this.data.acr_ind_key = this.newNonMemberTestimonySpeaker.value
        this.presenterOption.push(this.newNonMemberTestimonySpeaker)
        await this.getIndividualTestimonyType(this.newNonMemberTestimonySpeaker.value)
        await this.clearNewNonMemberTestimonySpeaker()
        if (this.individualTestimonyType !== null || this.individualTestimonyType !== '')
          this.tst_key = this.individualTestimonyType.tst_key
      }
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        await this.page_load(),
        this.getViewTranslations('buttons'),
        this.getComponentTranslations('common.testimony-type')
      ]).then(results => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common }
        }
        this.$set(this.translations, 'components', translatedText)
        this.translateDropdownOptions([{ node: 'testimony-type', store: 'optionTypes' }])
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      individualTestimonyType: 'directory/individualTestimonyType',
      newNonMemberTestimonySpeaker: 'user/newNonMemberTestimonySpeaker',
      SearchValues: 'userModule/getSearchValues',
      testimonyTypes: 'directory/testimonyTypes',
      userCountry: 'user/userCountryObject',
      userId: 'user/userId',
      userStateKey: 'user/userStateKey',
      prefCulture: 'user/userPreferredCulture',
    }),
    isNonMemberAdd() {
      return this.SearchValues.member === 1
    },
    optionTypes() {
      return this.testimonyTypes.map((type) => {
        return { value: type.tst_key, text: type.display_text }
      })
    },
  },
  components: {
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    PersonSearch: PersonSearch,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
